import React from "react";

import SolutionView from "domains/Solutions";
import SEO from "components/seo";

function SolutionPage() {
  return (
    <>
      <SEO
        title="Human Detection and Recognition"
        description="Enhance security and safety of your company through AI-powered CCTV. Recognize face and analyze people's traffic behavior with up to 98% accuracy!"
      />
      <SolutionView title="human detection and recognition" />
    </>
  );
}

export default SolutionPage;
